import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { IExternalRoute, IRoute } from "@/models/IRoute";
import * as Router from "@/router";

jest.mock("@/services/MyRentalsConfig");

const internalRoute: IRoute = {
  path: "/test",
};
const externalRoute: IExternalRoute = {
  isExternal: true,
  domain: "https://app.housfy.com",
  path: "/test",
};
const internalRouteInNewWindow = {
  ...internalRoute,
  options: { shouldOpenNewWindow: true },
};
const externalRouteInNewWindow = {
  ...externalRoute,
  options: { shouldOpenNewWindow: true },
};

const component = (data: any = {}, props: any = {}) => {
  return myRentalsShallowMount(HousfyLink, {
    data,
    props,
  });
};

describe("HousfyLink", () => {
  testMyRentalsShallowMount(HousfyLink, {
    props: { to: internalRoute },
    router: true,
  });

  describe("External", () => {
    it("should know if its external", () => {
      // given
      // when
      const HousfyLink = component({}, { to: externalRoute });
      // then
      expect(HousfyLink.vm.external).toBeTruthy();
    });
    it("should know if its not external", () => {
      // given
      // when
      const HousfyLink = component({}, { to: internalRoute });
      // then
      expect(HousfyLink.vm.external).toBeFalsy();
    });
  });

  describe("Target", () => {
    it("should know if internal route has _blank target", () => {
      // given
      // when
      const HousfyLink = component({}, { to: internalRouteInNewWindow });
      // then
      expect(HousfyLink.vm.target).toBe("_blank");
    });
    it("should know if internal route has no target", () => {
      // given
      // when
      const HousfyLink = component({}, { to: internalRoute });
      // then
      expect(HousfyLink.vm.target).toBeNull();
    });
    it("should know if external route has _blank target", () => {
      // given
      // when
      const HousfyLink = component({}, { to: externalRouteInNewWindow });
      // then
      expect(HousfyLink.vm.target).toBe("_blank");
    });
    it("should know if external route has no target", () => {
      // given
      // when
      const HousfyLink = component({}, { to: externalRoute });
      // then
      expect(HousfyLink.vm.target).toBeNull();
    });
  });

  describe("Path", () => {
    it("should get path from router", () => {
      // given
      spyOn(Router, "resolveRoutePath");
      // when
      component({}, { to: externalRoute });
      // then
      expect(Router.resolveRoutePath).toHaveBeenCalledWith(externalRoute);
    });
  });
});
